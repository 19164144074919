<template>
  <div class="menu-tab-labels">
    <ul class="menu-tab-labels__list">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'is-active': activeIndex === index, 'menu-tab-labels__item': true }"
        @click="onClick(index)"
      >
        <div class="menu-tab-labels__link" :style="{ backgroundColor: item.color }">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "MenuTabLabels",
  props: {
    items: {
      default: function() {
        return [];
      },
    },
  },
  methods: {
    onClick(index) {
      this.activeIndex = index;
      this.$emit("change", { index, item: this.items[index] });
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  created() {},
  components: {},
});
</script>
