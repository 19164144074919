<template>
  <div class="follow-ups-list-container">
    <Header
      :details="{
        back: false,
        title: 'Customer management',
      }"
    />
    <CustomerMenuTabs />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex flex-align-items-center sub-header-options">
          <Select
            v-if="canShowFilters"
            :options="filterActions"
            class="u-ml-15"
            @input="onFilterActionChange"
            :value="selectedFilterAction"
          ></Select>
          <div class="search" v-if="searchActivated" style="margin-left: 20px;">
            <input type="text" v-model="search" :placeholder="$t('overall.search')" />
          </div>
        </div>
      </header>
      <MenuTabLabels :items="menuTabLabels" @change="onMenuTabLabelsChange"></MenuTabLabels>
      <div class="table-list">
        <ul class="labels">
          <li @click="orderListBy('id', clients)" class="table-list__line table-list__line--fixed-width">
            <button>{{ "ID" }}</button>
          </li>
          <li @click="orderListBy('first_name', clients)" class="table-list__line">
            <button>{{ "Client name" }}</button>
          </li>
          <li @click="orderListBy('last_event.title', clients)" class="table-list__line">
            <button>{{ "Next task" }}</button>
          </li>
          <li @click="orderListBy('collaborators_string', clients)" class="table-list__line">
            <button>{{ "Assigned agent" }}</button>
          </li>
          <li @click="orderListBy('come_over_date', clients)" class="table-list__line">
            <button>{{ "Come over date" }}</button>
          </li>
          <li
            @click="orderListBy('active', clients)"
            class="table-list__line table-list__line--fixed-width"
            style="--width: 7%"
          >
            <button>{{ "Active" }}</button>
          </li>
        </ul>
        <div class="elements" v-if="clientsFiltered.length">
          <template v-if="!loading">
            <div class="line" v-for="client in elementsToDisplay" :key="client.token">
              <a>
                <div class="table-list__line table-list__line--fixed-width">
                  <router-link :to="{ name: 'edit-client', params: { token: client.token } }">
                    {{ client.id }}
                  </router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: client.token } }">
                    {{ client.first_name ? client.first_name + " " + client.last_name : "-" }}
                  </router-link>
                </div>
                <div class="table-list__line">
                  {{ client.last_event ? client.last_event.title : "/" }}
                </div>
                <div class="table-list__line" v-html="formatCollaboratorsStr(client.collaborators, '/')"></div>
                <div class="table-list__line">{{ dateToDayMonth(client.come_over_date) }}</div>
                <div
                  class="table-list__line table-list__line--fixed-width u-flex u-flex-align-i-center u-flex-justify-c-center"
                  style="--width: 7%"
                >
                  <ToggleButton v-model="client.active" @input="onToggleClientActive($event, client)" class="u-ml-0" />
                </div>
              </a>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("Loading follow ups...") }}</span>
          <span class="no-element" v-if="!elementsToDisplay.length && !loading">{{ $t("No follow ups") }}</span>
        </div>
      </div>
      <Pagination
        v-if="clientsFiltered.length"
        :value="clientsFiltered"
        @changeCurrentPage="elementsToDisplay = $event"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import modalBus from "@/bus/modal-bus";
import CustomerMenuTabs from "@/pages/customers/components/CustomerMenuTabs";
import MenuTabLabels from "@/components/MenuTabLabels";
import mixFilterClientsActions from "@/mixins/filterClientsActions.js";
import mixCustomerCore from "@/mixins/customerCore";
import { mapGetters, mapActions } from "vuex";

export default Vue.extend({
  name: "ClientsList",
  components: { CustomerMenuTabs, MenuTabLabels },
  mixins: [mixFilterClientsActions, mixCustomerCore],
  data() {
    return {
      isFilters: false,
      searchActivated: true,
      loading: true,
      elementsToDisplay: [],
      filters: {},
      search: "",
      clients: [],
      currentStatus: {},
      menuTabLabels: [],
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        let from = undefined;
        if (this.selectedFilterAction !== "all") {
          from = this.selectedFilterAction;
        }
        if (this.selectedFilterAction === "mine") {
          from = this.user.id;
        }
        let url = "/api/follow-ups/search?search=" + this.search + "&from=" + from;
        this.$axios.get(url).then((res) => {
          this.clients = res.data.map((item) => {
            item.come_over_date = item.come_over_date ? item.come_over_date : "";
            return item;
          });
          this.loading = false;
          this.timer = null;
          if (!this.clients.length) {
            this.elementsToDisplay = [];
          }
        });
      }, 300);
    },
  },
  methods: {
    ...mapActions({
      fetchFollowUps: "followUps/fetchFollowUps",
      fetchStatus: "followUps/fetchStatus",
      editFollowUpClient: "followUps/editFollowUpClient",
    }),
    onToggleClientActive(value, client) {
      // Update client active field
      this.editFollowUpClient({ client });
      this.$axios.post(`/api/clients/${client.token}/update-active`, { active: value });
    },
    async fetchStatusAndCreateMenu() {
      await this.fetchStatus();
      this.menuTabLabels = this.status.map((item) => {
        return { title: item.name, color: item.color, type: { ...item } };
      });
    },
    onMenuTabLabelsChange({ index, item }) {
      this.currentStatus = item.type;
    },
    // str format: YYYY-MM-DD
    dateToDayMonth(str) {
      return str ? this.moment(str, "YYYY-MM-DD").format("MMMM YYYY") : "/";
    },
    /**
     * @override from mixin
     * @returns {Promise<void>}
     */
    async fetchClients() {
      let from = undefined;
      if (this.selectedFilterAction !== "all") {
        from = this.selectedFilterAction;
      }
      if (this.selectedFilterAction === "mine") {
        from = this.user.id;
      }
      await this.fetchFollowUps({ from });
      const clients = [...this._followUps];
      clients.map((client) => {
        client.collaborators_string = this.formatCollaboratorsStr(client.collaborators);
        return client;
      });
      this.clients = clients;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      _followUps: "followUps/followUps",
      status: "followUps/status",
    }),
    clientsFiltered() {
      let status = this.currentStatus;
      if (status.id === undefined) {
        status = { ...this.status[0], type: this.status[0] };
      }
      return this.clients.filter((followUp) => {
        return followUp.status.id === status.id;
      });
    },
  },
  async created() {
    if (!this.canShowFilters) {
      this.selectedFilterAction = "mine";
    }
    await this.fetchStatusAndCreateMenu();
    const response = await this.$axios.get("/api/users/profile");
    await this.$store.dispatch("setupProfile", response.data);
    await this.fetchClients();
    this.firstLoad = false;
    if (!this.currentStatus.id) {
      this.currentStatus = { ...this.status[0], type: this.status[0] };
    }
  },
});
</script>
